// import channel from "./channel";
import producthome from "./producthome";
import home from "./home";
import auth from "./auth";
import feed from "./feed";
import post from "./post";
import category from "./category";
import subcategory from "./subcategory";
import product from "./product";
import page from "./page";
import pagecategory from "./pagecategory";
import channel from "./channel";
import ptag from "./ptag";
import elearning from "./elearning";
import job from "./job";
import author from "./author";
import topic from "./Topic";
import cart from "./cart";
import wishlist from "./wishlist";
import placeOrder from "./placeOrder";
import learningTracker from "./learningTracker";
import student from "./student";
import google from "./google";
import admin from "./admin";
import user from "./user";
import membershipPlan from "./membershipPlan";
import membership from "./membership";
import payment from "./payment";
import members from "./members";
import ecommerce1 from "./ecommerce-1";
import organisation from "./organisation";
import network from "./network";
import master from "./master";
import registration from "./registration";
import awards from "./awards";
import coupon from "./coupon";
import contactUs from "./contactUs";
export default {
  auth: auth,
  channel: channel,
  home: home,
  producthome: producthome,
  category: category,
  subcategory: subcategory,
  product: product,
  page: page,
  pagecategory: pagecategory,
  feed: feed,
  post: post,
  ptag: ptag,
  elearning,
  job,
  author,
  contactUs,
  topic,
  cart,
  wishlist,
  placeOrder,
  learningTracker,
  student,
  google,
  admin,
  user,
  membershipPlan,
  membership,
  members,
  payment,
  ecommerce1,
  organisation,
  network,
  master,
  registration,
  awards,
  coupon,
};
