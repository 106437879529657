import Repository from "./Repository";

const CHANNEL = "/channel";
const getSingleUser = "/user/getsingle";
const getSingleforinvoice = "/user/getsingle";
const getSingle = "/user/GetSingleLogin";
const getAll = "/user/getall";
const profileUpdate = "/user/update";
const savejob = "/user/user/savejob";
const UPDATE = "/user/update";
const CREATE = "/user/create";
const GETTICKER = "/user/getTicker";
const UPDATEEMAIL = "/user/updateEmail";
const UPDATETICKER = "/user/UpdateTicker";
const INACTIVEUSERS = "/user/inactiveUsers";
const GETINACTIVEUSERS = "/user/getInActiveUsers";
const ACTIVATEUSER = "/user/activateUser";
const DELETEDUSERS = "/user/deletedUser";
const SEARCHRESULT = "/user/searchResult";

export default {
  getAll(payload) {
    return Repository.post(transformRoute(getAll), payload);
  },
  getSingleUser(payload) {
    return Repository.post(transformRoute(getSingleUser), payload);
  },
  async profileUpdate(payload) {
    return Repository.post(transformRoute(profileUpdate), payload);
  },
  getSingle(payload) {
    return Repository.post(transformRoute(getSingle), payload);
  },
  getSingleforinvoice(payload) {
    return Repository.post(transformRoute(getSingleforinvoice), payload);
  },
  createUser(payload) {
    return Repository.post(transformRoute(CREATE), payload);
  },
  inactiveUsers(payload) {
    return Repository.post(transformRoute(INACTIVEUSERS), payload);
  },
  getInActiveUsers(payload) {
    return Repository.post(transformRoute(GETINACTIVEUSERS), payload);
  },
  deletedUsers(payload) {
    return Repository.post(transformRoute(DELETEDUSERS), payload);
  },
  ActivateUser(payload) {
    return Repository.post(transformRoute(ACTIVATEUSER), payload);
  },

  savejob(payload) {
    return Repository.post(savejob, payload);
  },
  updateUser(payload) {
    return Repository.post(transformRoute(UPDATE), payload);
  },
  ticker(payload) {
    return Repository.post(transformRoute(GETTICKER), payload);
  },
  UpdateTicker(payload) {
    return Repository.post(transformRoute(UPDATETICKER), payload);
  },
  updateEmail(payload) {
    return Repository.post(transformRoute(UPDATEEMAIL), payload);
  },
  searchResult(payload) {
    return Repository.post(transformRoute(SEARCHRESULT), payload);
  },
};

const transformRoute = (route) => {
  return `/nhrdn${route}`;
};
