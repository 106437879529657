import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const GETALL = "";
const GETSINGLE = "";

export default {
  getAlMemberships(payload) {
    return Repository.get(transformRoute(GETALL), payload);
  },
  getSingleMembership(payload) {
    console.log(payload);
    return Repository.get(transformRoute(GETSINGLE + "/" + payload));
  },
};

const transformRoute = (route) => {
  return `/nhrdn/plan/${route}`;
};
