import RepositoryFactory from "@/apis";
const auth = RepositoryFactory.auth;
const user = RepositoryFactory.user;

export default {
  signupWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.signupWithPassword(payload);

        // Set accessToken
        localStorage.setItem("token", data.data.token);

        // Update user details
        commit("UPDATE_USER_INFO", data.data.user);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  generateOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.generateOTP(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.getOtp(payload);

        commit("GET_OTP", { key: "otp", value: "data.data" });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  loginWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.loginWithPassword(payload);
        // Set accessToken
        localStorage.setItem("token", data.data.token);

        // Update user details
        commit("UPDATE_USER_INFO", data.data.user);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  VerifyOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.VerifyOTP(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Forgotpass({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.ForgotPass(payload);
        console.log("action");
        commit("FORGOT_PASS", { key: "forgotPasword", value: "data.data" });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  forgotPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.forgotPassword(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  ResetPasswordLink({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.resetPasswordLink(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  logout({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.logout(payload);
        commit("UPDATE_USER_INFO", {});
        // await commit("logout", null, { root: true });
        window.localStorage.clear();
        localStorage.removeItem("token");
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateInfo({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.updateProfile(payload);
        // console.log(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateProfilePic({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.updateProfilePic(payload);
        // console.log(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateCV({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.updateCV(payload);
        // console.log(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateUserProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(payload);
        const { data } = await auth.updateUserProfile(payload);

        commit("UPDATE_USER_IMAGE", data.data.userImage);
        console.log(data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateUserCoverImage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.updateUserCoverImage(payload);
        console.log(data);
        commit("UPDATE_USER_COVER_IMAGE", data.data.coverImage);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.getSingleUser(payload);
        commit("UPDATE_USER_INFO", data.data[0]);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  ticker({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.ticker(payload);
        commit("TICKER_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateTicker({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.UpdateTicker(payload);
        // commit("TICKER_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateEmail({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await user.updateEmail(payload);
        // commit("TICKER_DATA", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
