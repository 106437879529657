import RepositoryFactory from "@/apis";
const memPlan = RepositoryFactory.membershipPlan;

export default {
  getAllMemberships({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await memPlan.getAlMemberships(payload);
        commit("SET_MEMBERSHIPS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleMembership({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await memPlan.getSingleMembership(payload);
        commit("SET_SINGLEMEMBERSHIP", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
