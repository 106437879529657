import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const CREATE = "create";
const GetAll = "GetAll";
const GET = "get";
const JOIN = "join";
const ACCEPT_JOIN = "join/accept";
const REJECT_JOIN = "join/reject";
const REMOVE_MEMBER = "member/remove";
const NON_MEMBERS = "member/non-members";
const CHANNEL_MEMBERS = "member/memberbyChannelId";
const ADD_MEMBER = "member/add";
const EDIT = "edit";
const adminChannelEdit = "adminChannelEdit";
const UPDATE_ROLE = "member/UpdateRole";
const DELETE_CHANNEL = "delete";
const GET_MEMBER_BY_ID = "member/GetMemberById";
const GET_CHANNEL_CATEGORY = "channelCategory";
const CHANNEL = "";
export default {
  getChannels(payload) {
    return Repository.get(`/user/channel`, { params: payload });
  },
  CreateChannel(payload) {
    return Repository.post(transformRoute(CREATE), payload, formDataConfig);
  },
  GetAllChannel(payload) {
    return Repository.get(transformRoute(GetAll), payload);
  },
  GetChannel(payload) {
    return Repository.post(transformRoute(GET), payload);
  },
  joinChannel(payload) {
    return Repository.post(transformRoute(JOIN), payload);
  },
  acceptJoin(payload) {
    return Repository.post(transformRoute(ACCEPT_JOIN), payload);
  },
  rejectJoin(payload) {
    return Repository.post(transformRoute(REJECT_JOIN), payload);
  },
  removeMember(payload) {
    return Repository.post(transformRoute(REMOVE_MEMBER), payload);
  },
  getNonMembers(payload) {
    return Repository.post(transformRoute(NON_MEMBERS), payload);
  },
  // here
  memberbyChannelId(payload) {
    return Repository.post(transformRoute(CHANNEL_MEMBERS), payload);
  },
  addMember(payload) {
    return Repository.post(transformRoute(ADD_MEMBER), payload);
  },
  editChannel(payload) {
    return Repository.post(transformRoute(EDIT), payload);
  },
  adminChannelEdit(payload) {
    return Repository.post(transformRoute(adminChannelEdit), payload);
  },
  updateRole(payload) {
    return Repository.post(transformRoute(UPDATE_ROLE), payload);
  },
  DeleteChannel(payload) {
    return Repository.post(transformRoute(DELETE_CHANNEL), payload);
  },
  GetMemberById(payload) {
    return Repository.post(transformRoute(GET_MEMBER_BY_ID), payload);
  },
  getChannelCategories(payload) {
    return Repository.get(transformRoute(GET_CHANNEL_CATEGORY));
  },
};

const transformRoute = (route) => {
  return `/channel/${route}`;
};
